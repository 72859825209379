import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {RootState, store} from "../../../redux/store";
import {useSelector} from "react-redux";
import {
    setSelectEpipremnumThunk
} from "../../../redux/epipremnum";
import Box from "@mui/material/Box";
import {elevation, margin, padding, spacing} from "../../../Resources/styles";
import {
    Button,
    CircularProgress, Fab,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar, Paper, Tooltip,
    Typography
} from "@mui/material";
import Image from 'mui-image'
import moment from "moment";
import {Strings} from "../../../Resources/Strings";
import {IEpipremnumRawPictureOutput} from "../../../api/IEpipremnumRawPictureOutput";
import {epipremnumRawPictureService} from "../../../api/epipremnumRawPictureService";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import {useConfirm} from "material-ui-confirm";
import Edit from '@mui/icons-material/Edit';
import {EpipremnumAssignComponent} from "./epipremnumAssignComponent";
import {EpipremnumStatusConstants} from "../../../api/epipremnumStatus";
import {epipremnumService} from "../../../api/epipremnumService";
import {setInProgressOff, setInProgressOn} from "../../../redux/progress";

export const EpipremnumDetailsComponent = () => {

    const {id} = useParams()
    const navigate = useNavigate()
    const {currentEpipremnumSelected} = useSelector((state: RootState) => state.epipremnum);
    const [isSalable, setIsSalable] = useState<boolean>(false)

    const [epipremnumRawPictures, setEpipremnumRawPictures] = useState<IEpipremnumRawPictureOutput[]>()

    useEffect(() => {
        store.dispatch(setSelectEpipremnumThunk(id!))
    }, [])

    useEffect(() => {
        if (currentEpipremnumSelected) {
            loadData()
        }
    }, [currentEpipremnumSelected])

    const loadData = async () => {
        if (!currentEpipremnumSelected) return
        let pictures = await epipremnumRawPictureService.readEpipremnumPicturesAsync(id!)
        setEpipremnumRawPictures(pictures)
        let item = await epipremnumService.isSalable(id!)
        setIsSalable(item.status)
    }

    const getPictureTitle = (input: IEpipremnumRawPictureOutput) => {
        return `${moment(input.pictureDate).toDate().toLocaleDateString()}`
    }
    const getPictureSubtitle = (input: IEpipremnumRawPictureOutput) => {
        return `${input.pictureType} ${input.width}x${input.height} px`
    }

    const onPictureClickCallback = (input: IEpipremnumRawPictureOutput) => {
        navigate(`/epipremnumRawPicture/${input.id}`)
    }

    const setSalableCallback = async () => {
        await store.dispatch(setInProgressOn())
        await epipremnumService.setStatus(id!, EpipremnumStatusConstants.AvailableSalable)
        await store.dispatch(setSelectEpipremnumThunk(id!))
        await store.dispatch(setInProgressOff())
    }

    if (!currentEpipremnumSelected)
        return <></>

    if (currentEpipremnumSelected.status == EpipremnumStatusConstants.Unavailable)
        return <EpipremnumAssignComponent/>


    return <Box margin={margin}>
        <Typography variant='h6' fontWeight='bold'>
            {Strings.Epipremnum} {id} {currentEpipremnumSelected.name}
        </Typography>
        <Grid container spacing={spacing} alignItems='flex-start'>
            <Grid item xs='auto'>
                <Image alt="thumbnail" src={currentEpipremnumSelected.thumbnail} height={80} width={80}
                       style={{borderRadius: "50%"}} showLoading={<CircularProgress/>} fit='contain'/>
            </Grid>

            <Grid item xs='auto'>
                <Typography fontWeight='bold'>{Strings.CreationDate}</Typography>
                <Typography>{moment(currentEpipremnumSelected.creationDate).toDate().toLocaleDateString('es-Es')}</Typography>
            </Grid>

            <Grid item xs='auto'>
                <Typography fontWeight='bold'>{Strings.PlantDate}</Typography>
                <Typography>{moment(currentEpipremnumSelected.plantDate).toDate().toLocaleDateString('es-Es')}</Typography>
            </Grid>

            <Grid item xs='auto'>
                <Typography fontWeight='bold'>{Strings.Status}</Typography>
                <Typography>{currentEpipremnumSelected.status}</Typography>
            </Grid>

            <Grid item xs='auto'>
                <Typography fontWeight='bold'>{Strings.MercadoLibre}</Typography>
                {!currentEpipremnumSelected.mercadoLibreId && isSalable && <>
                    <Button variant='outlined' onClick={setSalableCallback}
                            color='primary'>{Strings.SetSalable}</Button>
                </>}
                {currentEpipremnumSelected && <>
                    <Typography>{currentEpipremnumSelected.mercadoLibreId}</Typography>
                </>}
            </Grid>

            <Grid item xs='auto'>
                <Typography fontWeight='bold'>{Strings.Price}</Typography>
                <Typography>{currentEpipremnumSelected.priceClp}</Typography>
            </Grid>


            <Grid item xs>
                <Typography fontWeight='bold'>{Strings.Description}</Typography>
                <Typography align='justify'>{currentEpipremnumSelected.description}</Typography>
            </Grid>

            <Grid item xs='auto'>
                <Tooltip title={Strings.TakePicture}>
                    <Fab onClick={() => navigate(`/epipremnumRawPicture/create/${id}`)} color='primary' size='small'>
                        <CameraAltIcon/>
                    </Fab>
                </Tooltip>
            </Grid>


            <Grid item xs='auto'>
                <Tooltip title={Strings.Edit}>
                    <Fab onClick={() => navigate(`/epipremnum/edit/${id}`)} color='primary' size='small'>
                        <Edit/>
                    </Fab>
                </Tooltip>
            </Grid>

        </Grid>

        <Paper elevation={elevation}>
            <Box margin={margin} padding={padding}>
                {epipremnumRawPictures && <div className='center-image-list'>
                    <ImageList
                        variant="woven"
                        cols={2} gap={8}>
                        {epipremnumRawPictures.map(value =>
                            <ImageListItem key={value.id} sx={{borderRadius: 50}}>
                                <Image alt={value.id} src={value.sharableLink}
                                       showLoading={<CircularProgress/>} fit='contain'/>
                                <ImageListItemBar title={getPictureTitle(value)}
                                                  subtitle={getPictureSubtitle(value)}
                                                  actionPosition='left'
                                                  actionIcon={
                                                      <IconButton onClick={() => onPictureClickCallback(value)}
                                                                  sx={{color: 'white'}}
                                                                  aria-label={`star ${value.id}`}>
                                                          <KeyboardDoubleArrowRightIcon/>
                                                      </IconButton>
                                                  }
                                                  position='bottom'>
                                </ImageListItemBar>
                            </ImageListItem>
                        )}
                    </ImageList>
                </div>}

                {!epipremnumRawPictures && <div className='center-image-list'>
                    <CircularProgress title={Strings.LoadingRawPictures} color='info'/>
                </div>}
            </Box>
        </Paper>
    </Box>

}