import {Box, Paper, Typography} from "@mui/material";
import {margin} from "../../../Resources/styles";
import {useEffect} from "react";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";
import preval from 'preval.macro';
import moment from "moment";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

export const HomeComponent = () => {
    const confirm = useConfirm()
    const {enqueueSnackbar} = useSnackbar()
    const getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        return lastUpdateMoment.format('DD-MM-YYYY HH:mm:ss');
    };
    useEffect(() => {

    }, [])

    return <Box m={margin}>

        <Typography variant='h5'>
            Bienvenido a Epipremnum Depot
        </Typography>

        <Typography variant='body1'>
            En esta plataforma podras ver el historial de tratamientos de la planta adquirida en epipremnum.cl además de
            visualizar los ascendientes y descendientes de tu nueva compañera
        </Typography>



        <Box sx={{
            marginTop: 'calc(10% + 60px)',
            position: 'fixed',
            bottom: 0,
            width: '100%'
        }} component="footer"><Typography>
            {getDateString()}
        </Typography>
        </Box>
    </Box>
}
