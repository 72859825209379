import {RefObject} from "react";
import {fabric} from "fabric";

export const resizePictureInFabricCanvas = (fabricCanvasRef: RefObject<fabric.Canvas | undefined>,
                                            pictureRef: RefObject<fabric.Image| undefined>,
                                            canvasRef: RefObject<HTMLCanvasElement>) => {
    if (!fabricCanvasRef.current || !pictureRef.current || !canvasRef.current)
        return false
    let fabricCanvas = fabricCanvasRef.current!
    let picture = pictureRef.current!
    let canvas = canvasRef.current!

    const bbox = canvas.getBoundingClientRect()

    const height = window.innerHeight - bbox.top;
    const width = window.innerWidth - bbox.left;

    fabricCanvas?.setHeight(height)
    fabricCanvas?.setWidth(width)

    let ratioWidth = width / picture.width!
    let ratioHeight = height / picture.height!

    if (ratioWidth > ratioHeight)
        picture.scale(ratioHeight)
    else picture.scale(ratioWidth)

    let pictureOffsetX = (width - picture.getScaledWidth()) / 2
    let pictureOffsetY = (height - picture.getScaledHeight()) / 2

    picture.set({
        left: pictureOffsetX,
        top: pictureOffsetY,
    })
    return true
}


