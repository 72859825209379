import {Box, Button, Fab, Grid, IconButton, Typography} from "@mui/material";
import {Strings} from "../../../Resources/Strings";
import {reportService} from "../../../api/reportService";
import {store} from "../../../redux/store";
import {setInProgressOff, setInProgressOn} from "../../../redux/progress";
import {margin, padding, spacing} from "../../../Resources/styles";
import {Download} from "@mui/icons-material";

export const ReportsComponent = () => {
    const reportSalablePlantsCallback = async () => {
        await store.dispatch(setInProgressOn())
        await reportService.downloadSalableReport()
        await store.dispatch(setInProgressOff())
    }

    return <Box m={margin} p={padding}>
        <Grid container spacing={spacing}>
            <Grid item xs>
                <Typography variant='h5'> {Strings.ReportSalablePlants}</Typography>
            </Grid>
            <Grid item xs='auto'>
                <Fab onClick={reportSalablePlantsCallback} size='small' variant='circular'
                     color='primary'>
                    <Download/>
                </Fab>
            </Grid>
        </Grid>
    </Box>
}