import {apiUrl, epipremnumApiUtils} from "./ApiConfig";
import moment from "moment";
import {IMercadoLibrePendingOrder} from "./IMercadoLibrePendingOrder";

class ReportService {
    async downloadSalableReport() {
        const requestOptions = epipremnumApiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}report/salable_report`, requestOptions);
        let blob = await response.blob()

        const url = window.URL.createObjectURL(
            new Blob([blob]),
        );
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute(
            'download',
            `Catalog ${moment().toISOString()}.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode!.removeChild(link);
    }

    async mercadoLibrePendingOrders() {
        const requestOptions = epipremnumApiUtils.getBaseRequest('GET')
        let response = await fetch(`${apiUrl}/Report/mercadolibre_pending_orders`, requestOptions);
        return await response.json() as IMercadoLibrePendingOrder[]
    }
}

export const reportService = new ReportService()