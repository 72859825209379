import * as React from 'react';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import {useEffect, useState} from "react";
import './App.css';
import {AppBar, Drawer, IconButton, Toolbar, Typography} from "@mui/material";
import {Strings} from "./Resources/Strings";
import {GlobalProgressComponent} from "./components/global/globalProgress/globalProgressComponent";
import {AuthenticationComponent} from "./components/global/authentication/authenticationComponent";
import {RoutesComponent} from "./routesComponent";
import {NavComponent} from "./navComponent";
import {useAuth0} from "@auth0/auth0-react";
import {AccessTokenKey} from "./api/ApiConfig";

export const App = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0()

    // Set the token configuration
    useEffect(() => {
        if (user)
            getAccessTokenSilently().then(value => {
                localStorage.setItem(AccessTokenKey, value)
            })
        else {

        }
    }, [user])


    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')) return;
            setIsOpen(open);
        };

    return <>
        <AppBar position="static">
            <Toolbar variant="dense">
                <IconButton edge="start" color="inherit" aria-label="menu" sx={{mr: 2}}
                            onClick={toggleDrawer(true)}>
                    <MenuIcon/>
                </IconButton>

                <Typography variant="h5" color="secondary" component="div" sx={{flexGrow: 1}}>
                    {Strings.Epipremnum}
                </Typography>

                <AuthenticationComponent/>

            </Toolbar>
        </AppBar>


        <Drawer anchor='left' open={isOpen} onClose={toggleDrawer(false)}>
            <Box
                sx={{width: 250}}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}>
                <NavComponent/>
            </Box>
        </Drawer>
        <GlobalProgressComponent/>

        {/* Routes */}
        <RoutesComponent/>
    </>
}