import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import {Button, Divider, Typography} from "@mui/material";
import {auth0Audience} from "../../../api/ApiConfig";

export const UserDetailsComponent = () => {
    const {isAuthenticated, user, getIdTokenClaims, getAccessTokenSilently} = useAuth0()

    const [claims, setClaims] = useState<string[]>([])
    const [userItems, setUserItems] = useState<string[]>([])
    const [token, setToken] = useState<string>()

    useEffect(() => {

        if (isAuthenticated) {

            getAccessTokenSilently({audience: auth0Audience}).then(value => {
                setToken(value)
            })
            // Claims
            getIdTokenClaims().then(value => {
                setClaims(getKeyValues(value))
            })
            setUserItems(getKeyValues(user))
        }
    }, [isAuthenticated])

    const getKeyValues = (value: any) => {
        let dict = value as object

        let keys = Object.keys(dict);
        let values = Object.values(dict);

        let newArray: string[] = []
        for (const key of keys) {
            let currentValue = values[keys.indexOf(key)]
            newArray.push(`${key} ${currentValue}`)
        }
        return newArray
    }

    if (isAuthenticated && user) {
        return <>

            <iframe
                width='100%'
                height='512px'
                src="https://charts.mongodb.com/charts-epipremnum-lomaz/embed/dashboards?id=64809ce5-a88f-40db-8df1-011d4f5b287d&theme=light&autoRefresh=true&maxDataAge=3600&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=scale"></iframe>

            <>{token}</>
            <Button onClick={async () => {
                await navigator.clipboard.writeText(`${token}`)
            }} variant='contained'>Copy Token</Button>

            {userItems.map(userItem => {
                return <Typography flexWrap='wrap' key={userItem}>{userItem}</Typography>
            })}
            <Divider/>
            {claims.map(claim => {
                return <Typography flexWrap='wrap' key={claim}>{claim}</Typography>
            })}

        </>
    }

    return <>
        No Autenticado
    </>
}