export const apiUrl = process.env.REACT_APP_API_URL!;
export const auth0Url = process.env.REACT_APP_AUTH0_AUDIENCE! + "/roles";

export const auth0Audience = process.env.REACT_APP_AUTH0_AUDIENCE!;
export const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN!
export const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;

export const AccessTokenKey = 'accessToken'

class EpipremnumApiUtils {
    getBaseRequest(method: string) {
        let requestOptions: RequestInit = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': `Bearer ${localStorage.getItem(AccessTokenKey)}`
            }
        };
        return requestOptions
    }

    getNoCors(method: string) {
        let requestOptions: RequestInit = {
            mode: 'cors',
            method: method,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': 'true'
            }
        };
        return requestOptions
    }

    async getBase64Image(blob: Blob): Promise<string> {
        let reader = new FileReader()
        await new Promise((resolve, reject) => {
            reader.onload = resolve;
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
        return reader.result! as string
    }
}

export const epipremnumApiUtils = new EpipremnumApiUtils()

