import type {MRT_ColumnDef} from 'material-react-table';
import MaterialReactTable from 'material-react-table';
import {MRT_Localization_ES} from 'material-react-table/locales/es';
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {margin, spacing} from "../../../Resources/styles";
import {epipremnumService} from "../../../api/epipremnumService";
import moment from "moment";
import {Strings} from "../../../Resources/Strings";
import {IEpipremnumRow} from "./IEpipremnumRow";
import {CircularProgress, Grid, IconButton, Tooltip, Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {Link, useNavigate} from "react-router-dom";
import {useConfirm} from "material-ui-confirm";
import {store} from "../../../redux/store";
import {progress, setInProgressOff, setInProgressOn} from "../../../redux/progress";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import Image from "mui-image";
import {EpipremnumStatusConstants} from "../../../api/epipremnumStatus";

const columns: MRT_ColumnDef<IEpipremnumRow>[] = [
    {
        header: `${Strings.Name}`,
        Cell: ({row, cell}) => {
            return <Grid container spacing={spacing} alignItems='center' alignContent='center'>
                <Grid xs='auto' item>
                    <Image alt="thumbnail" src={row.original.thumbnail} height={40} width={40} easing='ease-in'
                           style={{borderRadius: "50%"}} showLoading={<CircularProgress/>} fit='contain'/>
                </Grid>
                <Grid xs='auto' item>
                    <Typography>{row.original.name}</Typography>
                </Grid>
            </Grid>

        },
        accessorKey: 'name',
        maxSize: 180,
    }, {
        header: `${Strings.Identifier}`,
        accessorKey: 'id',
        Cell: ({cell}) => <Link to={`/epipremnum/${cell.getValue<string>()}`}>
            <Typography>{cell.getValue<string>()}</Typography></Link>,
        size: 10,
        maxSize: 30,
    }, {
        header: `${Strings.Description}`,
        accessorKey: 'description',
        size: 80
    }, {
        header: `${Strings.PlantDate}`,
        accessorFn: (row: IEpipremnumRow) => moment(row.plantTime).toDate(),
        id: 'plantTime',
        Cell: ({cell}) => cell.getValue<Date>()?.toLocaleDateString('es-ES'),
        sortingFn: 'datetime',
        size: 10,
        maxSize: 30,
    }, {
        header: `${Strings.Status}`,
        accessorKey: 'status',
        filterVariant: 'select',
        filterSelectOptions: EpipremnumStatusConstants.getOptions(),
    }]


export const EpipremnumAllComponent = () => {
    const confirm = useConfirm()
    const [rowData, setRowData] = useState<IEpipremnumRow[]>([])
    const navigate = useNavigate()

    const loadDataAsync = async () => {
        try {
            await store.dispatch(setInProgressOn())
            const epipremnums = await epipremnumService.readAllAsync()
            let epipremnumRows = epipremnums.map(value => {
                const epipremnumRow: IEpipremnumRow = {
                    plantTime: value.plantDate,
                    description: value.description,
                    id: value.id,
                    status: value.status,
                    name: value.name,
                    thumbnail: value.thumbnail
                }
                return epipremnumRow
            })
            setRowData([...epipremnumRows])
        } catch (e) {
            console.error(e)
        } finally {
            await store.dispatch(setInProgressOff())
        }
    }

    useEffect(() => {
        loadDataAsync()
    }, [])

    const editCallback = async (id: string) => {
        try {

        } finally {

        }
    }

    return <Box m={margin}>
        <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <MaterialReactTable
                    renderTopToolbarCustomActions={() => (
                        <Box m={margin}>
                            <Typography fontWeight='bold'>{Strings.Epipremnum}</Typography>
                        </Box>
                    )}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'left',
                            },
                            size: 120
                        },
                    }}
                    columns={columns}
                    data={rowData}
                    enableColumnOrdering
                    enableColumnActions
                    enableRowActions
                    localization={MRT_Localization_ES}

                    renderDetailPanel={({row}) => (
                        <Grid spacing={spacing} container direction='row' alignItems='center'>
                            <Grid xs='auto' item>
                                <Image alt="thumbnail" src={row.original.thumbnail} height={150} width={150}
                                       easing='ease-in' duration={1}
                                       style={{borderRadius: "5%"}} showLoading={<CircularProgress/>} fit='contain'/>
                            </Grid>

                            <Grid item xs>
                                <Typography variant="h4">{row.original.name}</Typography>
                                <Typography
                                    variant="h4">{moment(row.original.plantTime).toDate().toLocaleString('es-ES')}</Typography>
                            </Grid>
                        </Grid>)}

                    renderRowActions={({row}) => (
                        <Box>
                            <Tooltip arrow placement="left" title={Strings.AddEpipremnumPhotographyRecord}>
                                <IconButton onClick={() => {
                                    navigate(`/epipremnumRawPicture/create/${row.getValue('id')}`)
                                }} size='small' color='primary'>
                                    <CameraAltIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip arrow placement="right" title={Strings.Edit}>
                                <IconButton color="primary" size='small' onClick={() => {
                                    navigate(`/epipremnum/edit/${row.getValue('id')}`)
                                }}>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    )}
                />
            </Grid>
        </Grid>
    </Box>
}