import {Link, useNavigate, useParams} from "react-router-dom";
import {useConfirm} from "material-ui-confirm";
import {useSnackbar} from "notistack";
import {useEffect, useState} from "react";
import {IEpipremnumRawPictureOutput} from "../../../api/IEpipremnumRawPictureOutput";
import {store} from "../../../redux/store";
import {setInProgressOff, setInProgressOn} from "../../../redux/progress";
import {epipremnumRawPictureService} from "../../../api/epipremnumRawPictureService";
import {margin, spacing} from "../../../Resources/styles";
import {Strings} from "../../../Resources/Strings";
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    ImageList,
    ImageListItem,
    TextField,
    Typography
} from "@mui/material";
import {Image} from "mui-image";
import {CommercialPictureConfiguration} from "../../../api/commercialPictureConfiguration";


export const EpipremnumRawPictureCreateCommercialPictureComponent = () => {
    let {id} = useParams()
    const navigate = useNavigate()
    const confirm = useConfirm()
    const snackbar = useSnackbar()

    const [alphaEpipremnumPicture, setAlphaEpipremnumPicture] = useState<IEpipremnumRawPictureOutput>()
    const [rawEpipremnumPicture, setRawEpipremnumPicture] = useState<IEpipremnumRawPictureOutput>()
    const [previewTransparentImage, setPreviewTransparentImage] = useState<string>()
    const [previewBackgroundImage, setPreviewBackgroundImage] = useState<string>()
    const [pictureConfiguration, setPictureConfiguration] =
        useState<CommercialPictureConfiguration>(new CommercialPictureConfiguration())

    const [selectedBackground, setSelectedBackground] = useState<string>()
    const [backgrounds, setBackgrounds] = useState<string[]>([])

    useEffect(() => {
        loadAsync()
    }, [id])

    const loadAsync = async () => {
        try {
            if (!id) return
            await store.dispatch(setInProgressOn())
            let alphaItem = await epipremnumRawPictureService.readAsync(id);
            setAlphaEpipremnumPicture(alphaItem)
            let rawItem = await epipremnumRawPictureService.readAsync(alphaItem.relatedImage!._id);
            setRawEpipremnumPicture(rawItem)
            let backgrounds = epipremnumRawPictureService.getCommercialBackgrounds()
            setBackgrounds(backgrounds)
            setSelectedBackground(backgrounds[0])
        } catch (e) {
            console.error(e)
        } finally {
            await store.dispatch(setInProgressOff())
        }
    }


    const previewCommercialPictureTransparentCallback = async () => {
        try {
            await store.dispatch(setInProgressOn())
            let previewImage = await epipremnumRawPictureService.previewTransparentPicture(id!, pictureConfiguration)
            setPreviewTransparentImage(previewImage)
        } catch (e) {

        } finally {
            await store.dispatch(setInProgressOff())
        }
    }

    const previewCommercialPictureBackgroundCallback = async () => {

        try {
            await store.dispatch(setInProgressOn())
            let previewImage = await epipremnumRawPictureService.previewBackgroundPicture(id!, pictureConfiguration)
            setPreviewBackgroundImage(previewImage)
        } catch (e) {

        } finally {
            await store.dispatch(setInProgressOff())
        }
    }

    const processCommercialPicturesCallback = async () => {
        try {
            await confirm()
            await store.dispatch(setInProgressOn())
            await epipremnumRawPictureService.createCommercialPictures(id!, pictureConfiguration)
            navigate(`/epipremnum/${alphaEpipremnumPicture!.epipremnumId}`)
        } catch (e) {
            console.error(e)
        } finally {
            await store.dispatch(setInProgressOff())
        }
    }

    if (!alphaEpipremnumPicture || !rawEpipremnumPicture)
        return <>
        </>

    return <Box m={margin}>
        <Typography variant='h6' fontWeight='bold'>
            {Strings.CommercialPicture} {id}
        </Typography>

        <Grid spacing={spacing} container alignItems='center'>
            <Grid item xs='auto'>
                <Typography fontWeight='bold'>
                    {Strings.Epipremnum}
                </Typography>
                <Link to={`/epipremnum/${alphaEpipremnumPicture.epipremnumId}`}>
                    <Typography>
                        {alphaEpipremnumPicture.epipremnumId}
                    </Typography>
                </Link>
            </Grid>

            <Grid item xs='auto'>
                <Button onClick={previewCommercialPictureTransparentCallback}
                        variant='outlined'>{Strings.PreviewTransparent}</Button>
            </Grid>
            <Grid item xs='auto'>
                <Button onClick={previewCommercialPictureBackgroundCallback}
                        variant='outlined'>{Strings.PreviewBackground}</Button>
            </Grid>

            <Grid item xs='auto'>
                <Button onClick={processCommercialPicturesCallback}
                        variant='contained'>{Strings.ProcessCommercialPictures}</Button>
            </Grid>

            <Grid item xs={6}>
                <Autocomplete
                    value={selectedBackground}
                    onChange={(event, value) => {
                        setSelectedBackground(value)
                        if (value) {
                            pictureConfiguration.backgroundUrl = value
                            setPictureConfiguration(pictureConfiguration)
                        }
                    }}
                    disablePortal
                    disableClearable
                    options={backgrounds}
                    sx={{maxWidth: 300}}
                    renderInput={(params) => <TextField {...params} label={Strings.Background}/>}
                />
                {selectedBackground && <ImageList>
                    <ImageListItem>
                        <Image alt='preview_background' src={selectedBackground}
                               showLoading duration={100} fit='contain'/>
                    </ImageListItem>
                </ImageList>}
            </Grid>
        </Grid>
        <Grid spacing={spacing} container>
            <Grid xs={12} item>
                <Typography fontWeight='bold'>
                    {Strings.RawPicture}
                </Typography>
                <ImageList>
                    <ImageListItem>
                        <Image alt='epipremnum_raw_image' src={alphaEpipremnumPicture.sharableLink}
                               duration={1} fit='contain'/>
                    </ImageListItem>
                </ImageList>
            </Grid>

            <Grid xs={12} item>
                <Typography fontWeight='bold'>
                    {Strings.AlphaPicture}
                </Typography>
                <ImageList>
                    <ImageListItem>
                        <Image alt='epipremnum_alpha_image' src={rawEpipremnumPicture.sharableLink}
                               duration={1} fit='contain'/>
                    </ImageListItem>
                </ImageList>
            </Grid>


            {previewTransparentImage && <Grid xs={12} item>
                <Typography fontWeight='bold'>
                    {Strings.PreviewTransparent}
                </Typography>
                <ImageList>
                    <ImageListItem>
                        <Image alt='preview_transparent' src={previewTransparentImage}
                               duration={100} fit='contain'/>
                    </ImageListItem>
                </ImageList>
            </Grid>}

            {previewBackgroundImage && <Grid xs={12} item>
                <Typography fontWeight='bold'>
                    {Strings.PreviewBackground}
                </Typography>
                <ImageList>
                    <ImageListItem>
                        <Image alt='preview_background' src={previewBackgroundImage}
                               duration={100} fit='contain'/>
                    </ImageListItem>
                </ImageList>
            </Grid>}
        </Grid>
    </Box>
}
