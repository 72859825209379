import {Box, LinearProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";

export const GlobalProgressComponent = () => {
    const {isInProgress} = useSelector((root: RootState) => root.progress)

    return <>
        {isInProgress && <LinearProgress variant='indeterminate' color='info'/>}
    </>
}