export class Strings {
    static AddRecord: string = "Añadir registro";
    static Status: string = "Status";
    static Report: string = "Reporte";
    static AddEpipremnumPhotographyRecord: string = "Añadir registro fotográfico epipremnum";
    static AddEpipremnumCuttingPhotographyRecord: string = "Añadir registro fotográfico esqueje";
    static LocateEpipremnum: string = "Localizar";
    static PlantDate: string = "Fecha de plantación";
    static ShoppingDate: string = "Fecha de compra";
    static EpipremnumCuttingStorage: string = "Contenedor esqueje";
    static EpipremnumStorage: string = "Contenedor epipremnum";
    static EpipremnumCuttingsStorage: string = "Contenedores esquejes";
    static EpipremnumsStorage: string = "Contenedores epipremnum";
    static EpipremnumCuttings = "Esquejes"
    static StorageEpipremnumCutting: string = "Almacenar esqueje";
    static In: string = "en";
    static PictureDate: string = "Fecha de fotografía";
    static PictureType: string = "Tipo de foto";
    static CreationDate: string = "Fecha de creación";
    static GoToDetails: string = "Ir a detalles";
    static AssignEpipremnumCutting: string = "Asignar esqueje";
    static TakePictureAgain: string = "Tomar foto denuevo";
    static MissingEpipremnumCutting: string = "Sin esqueje";
    static TakePicture: string = "Tomar foto";
    static MakeRoiAgain: string = "Hacer ROI de nuevo";
    static Create: string = "Crear";
    static CreateEpipremnum: string = "Crear Epipremnum";
    static CreateEpipremnumCutting: string = "Crear Esqueje Epipremnum ";
    static CuttingList: string = "Lista esquejes";
    static CuttingDate: string = "Fecha de corte";
    static Epipremnum: string = "Epipremnum";
    static Description: string = "Descripción";
    static Scan: string = "Escanear";
    static NotFounded404: string = "Error 404 —— No encontrado"
    static GenerateQr: string = "Generar QR";
    static Identifier: string = "Identificador"
    static CreatedEpipremnum: string = "Creado epipremnum"
    static AssignedEpipremnum: string = "Epipremnum Asignado"
    static AssignEpipremnum: string = "Asignar Epipremnum"
    static UnassignedEpipremnum: string = "Epipremnum Desasignado"
    static Undo: string = "Deshacer"
    static Go: string = "Ir"
    static Deleted: string = "Borrado"
    static Delete: string = "Borrar"
    static List: string = "Lista"
    static AreYouSureOfThisAction = "¿Esta seguro de esta accion?"
    static Yes = "Sí"
    static No = "No"
    static ConfirmEdition = "Confirmar edición"
    static Edit = "Editar"
    static EditEpipremnum = "Editar epipremnum"
    static ConfirmQrLocationAndSize = "Confirmar locación QR y tamaño"
    static Configuration = "Configuración"
    static QrSizes = "Tamaños QR"
    static SelectQrRealSize: string = "Seleccione el tamaño real del Código QR";
    static Ok: string = "Ok";
    static PixelSize: string = "Tamaño pixel"
    static CreatedPicture: string = "Imagen creada"
    static RawPicture: string = "Fotografía sin pulir"
    static AlphaPicture: string = "Fotografía alfa"
    static Width: string = "Ancho"
    static Height: string = "Alto"
    static WidthAndHeight: string = "Alto y ancho"
    static Original: string = 'Original';
    static Cropped: string = "Cortado";
    static LoadingRawPictures: string = "Cargando imagenes sin pulir";
    static CreateAnother: string = "Crear otra";
    static Logout: string = "Cerrar sesión"
    static UserDetails: string = "Detalles usuario";
    static CreatedEpipremnumCutting: string = "Creado esqueje epipremnum";
    static CreatedEpipremnumCuttingStorage: string = "Creado contenedor esqueje epipremnum";
    static CreateEpipremnumCuttingStorage: string = "Crear contenedor esqueje epipremnum";
    static CreateEpipremnumStorage: string = "Crear contenedor epipremnum";
    static ContainedEpipremnumCutting: string = "Esqueje almacenado";
    static ContainedEpipremnum: string = "Epipremnum almacenado";
    static WithoutEpipremnumCutting: string = "Sin esqueje";
    static WithoutEpipremnum: string = "Sin epipremnum";
    static EpipremnumCutting: string = "Esqueje";
    static EpipremnumCuttingRawPicture: string = "Imagen de esqueje sin pulir";
    static EpipremnumRawPicture: string = "Imagen sin pulir";
    static Unstore: string = "Desalmacenar";
    static From: string = "Desde";
    static To: string = "Hasta";
    static Creation: string = "Creación";
    static ContainerList: string = "Lista contenedores";
    static ContainerScan: string = "Escanear contenedor";
    static SetEpipremnumCutting: string = "Colocar esqueje";
    static HistoryRecords: string = "Historial de registros";
    static Ascendancy: string = "Ascendiente"
    static CreatedEpipremnumStorage: string = "Creado contenedor epipremnum"
    static IsAssigned: string = "Esta asignado"
    static Assign: string = "Asignar"
    static Name: string = "Nombre"
    static Sold: string = "Vendido"
    static Thumbnail: string = "Thumbnail"
    static MercadoLibre: string = "Mercado Libre"
    static UpdatedThumbnail: string = "Actualizado thumbnail"
    static AssignAsThumbnail:string = "¿Asignar como thumbnail?"
    static Forbidden:string = "Prohibido"
    static ProcessAlpha:string = "Procesar alfa"
    static ProcessCommercialPictures:string = "Procesar imágenes comerciales"
    static CommercialPicture:string = "Foto comercial"
    static CreateCommercialPicture:string = "Crear foto comercial"
    static PreviewTransparent:string = "Preview Transparente"
    static PreviewBackground:string = "Preview Background"
    static Background:string = "Background"
    static ReportSalablePlants:string = "Reporte plantas vendibles"
    static MercadoLibrePendingOrders:string = "Ordenes pendientes Mercado Libre"
    static Order:string = "Orden"
    static shippingId: string = "Id Shipping"
    static UserId: string= "Id Usuario"
    static FirstName: string = "Nombre"
    static LastName: string = "Apellido"
    static Email: string = "Email"
    static Rut: string = "Rut"
    static Cellphone: string = "Teléfono"
    static Commune: string = "Comuna"
    static StreetName: string = "Nombre Calle"
    static StreetNumber: string = "Número Calle"
    static Region: string = "Región"
    static Comment: string = "Comentarios"
    static ReceiverName: string = "Recibe"
    static City: string = "Ciudad"
    static AddressLine: string = "Dirección"
    static Price: string = "Precio"
    static SetSalable: string = "Setear vendible"
}