import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import GrassIcon from "@mui/icons-material/Grass";
import ListItemText from "@mui/material/ListItemText";
import {ListItemButton, Typography} from "@mui/material";
import {Strings} from "./Resources/Strings";
import {Link} from "react-router-dom";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import {DocumentScanner} from "@mui/icons-material";

export const NavComponent = () => {
    return <List>
        <ListItem key='epipremnum'>
            <ListItemIcon>
                <GrassIcon color='primary'/>
            </ListItemIcon>
            <ListItemText
                primary={<Typography variant='body1' fontWeight='bold'>{Strings.Epipremnum}</Typography>}/>
        </ListItem>

        <ListItemButton key='epipremnum_all'
                        component={Link} to='/epipremnum/all'>
            <ListItemIcon>
                <FormatListNumberedIcon color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.List}/>
        </ListItemButton>

        <ListItemButton key='epipremnum_create'
                        component={Link} to='/epipremnum/create'>
            <ListItemIcon>
                <AddBoxOutlinedIcon color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.Create}/>
        </ListItemButton>

        <ListItemButton key='epipremnum_scan'
                        component={Link} to='/epipremnum/scan'>
            <ListItemIcon>
                <QrCodeScannerOutlinedIcon color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.Scan}/>
        </ListItemButton>

        <ListItemButton key='report'
                        component={Link} to='/report'>
            <ListItemIcon>
                <DocumentScanner color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.Report}/>
        </ListItemButton>

        <ListItemButton key='mercadoLibrePendingOrders'
                        component={Link} to='/mercadoLibrePendingOrders'>
            <ListItemIcon>
                <DocumentScanner color='primary'/>
            </ListItemIcon>
            <ListItemText primary={Strings.MercadoLibrePendingOrders}/>
        </ListItemButton>
    </List>
}