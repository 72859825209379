import {Box, Fab, Grid, Paper, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {IMercadoLibrePendingOrder} from "../../../api/IMercadoLibrePendingOrder";
import {elevation, margin, padding, spacing} from "../../../Resources/styles";
import {Strings} from "../../../Resources/Strings";
import {reportService} from "../../../api/reportService";
import {setInProgressOff, setInProgressOn} from "../../../redux/progress";
import {store} from "../../../redux/store";

export const MercadoLibrePendingOrdersComponent = () => {

    const [orders, setOrders] = useState<IMercadoLibrePendingOrder[]>([])

    useEffect(() => {
        loadItems()
    }, [])

    const loadItems = async () => {
        try {
            await store.dispatch(setInProgressOn())
            let currentOrders = await reportService.mercadoLibrePendingOrders()
            setOrders(currentOrders)
        } catch (e) {
            console.error(e)
        } finally {
            await store.dispatch(setInProgressOff())
        }
    }

    return <Box m={margin} p={padding}>
        <Grid container spacing={spacing}>
            <Grid item xs={12}>
                <Typography variant='h5'> {Strings.MercadoLibrePendingOrders}</Typography>
            </Grid>

            {orders && orders.map((order, i) => {
                return <Grid item xs={12} key={i}>
                    <Paper elevation={elevation}>
                        <Box m={margin} p={padding}>
                            <Grid container spacing={spacing}>
                                <Grid item xs='auto'>
                                    <Typography fontWeight='bold'>
                                        {Strings.Order}
                                    </Typography>
                                    <Typography>
                                        {order.orderId}
                                    </Typography>
                                </Grid>


                                {order.firstName &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.FirstName}
                                        </Typography>
                                        <Typography>
                                            {order.firstName}
                                        </Typography>
                                    </Grid>}

                                {order.lastName &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.LastName}
                                        </Typography>
                                        <Typography>
                                            {order.lastName}
                                        </Typography>
                                    </Grid>}

                                {order.rut &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.Rut}
                                        </Typography>
                                        <Typography>
                                            {order.rut}
                                        </Typography>
                                    </Grid>}

                                {order.cellphone &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.Cellphone}
                                        </Typography>
                                        <Typography>
                                            {order.cellphone}
                                        </Typography>
                                    </Grid>}

                                {order.email &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.Email}
                                        </Typography>
                                        <Typography>
                                            {order.email}
                                        </Typography>
                                    </Grid>}

                                {order.receiverName &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.ReceiverName}
                                        </Typography>
                                        <Typography>
                                            {order.receiverName}
                                        </Typography>
                                    </Grid>}

                                {order.addressLine &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.AddressLine}
                                        </Typography>
                                        <Typography>
                                            {order.addressLine}
                                        </Typography>
                                    </Grid>}

                                {order.streetName &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.StreetName}
                                        </Typography>
                                        <Typography>
                                            {order.streetName}
                                        </Typography>
                                    </Grid>}

                                {order.streetNumber &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.StreetNumber}
                                        </Typography>
                                        <Typography>
                                            {order.streetNumber}
                                        </Typography>
                                    </Grid>}

                                {order.city &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.City}
                                        </Typography>
                                        <Typography>
                                            {order.city}
                                        </Typography>
                                    </Grid>}

                                {order.commune &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.Commune}
                                        </Typography>
                                        <Typography>
                                            {order.commune}
                                        </Typography>
                                    </Grid>}

                                {order.region &&
                                    <Grid item xs='auto'>
                                        <Typography fontWeight='bold'>
                                            {Strings.Region}
                                        </Typography>
                                        <Typography>
                                            {order.region}
                                        </Typography>
                                    </Grid>}

                                {order.comment &&
                                    <Grid item xs={6}>
                                        <Typography fontWeight='bold'>
                                            {Strings.Comment}
                                        </Typography>
                                        <Typography>
                                            {order.comment}
                                        </Typography>
                                    </Grid>}


                            </Grid>
                        </Box>
                    </Paper>
                </Grid>
            })}
        </Grid>
    </Box>
}