import {Route, Routes} from "react-router-dom";
import {HomeComponent} from "./components/global/home/homeComponent";
import {UserDetailsComponent} from "./components/global/userDetails/userDetailsComponent";
import {EpipremnumCreateComponent} from "./components/epipremnum/epipremnumCreate/epipremnumCreateComponent";
import {EpipremnumAllComponent} from "./components/epipremnum/epipremnumAll/epipremnumAllComponent";
import {
    EpipremnumRawPictureCreateComponent
} from "./components/epipremnumRawPicture/epipremnumRawPictureCreate/epipremnumRawPictureCreateComponent";
import {
    EpipremnumRawPictureDetailsComponent
} from "./components/epipremnumRawPicture/epipremnumCuttingRawPictureDetails/epipremnumRawPictureDetailsComponent";
import {GenericNotFoundedComponent} from "./components/global/genericNotFounded/genericNotFoundedComponent";
import * as React from "react";
import {useSelector} from "react-redux";
import {RootState} from "./redux/store";
import {EpipremnumScanComponent} from "./components/epipremnum/epipremnumScan/epipremnumScanComponent";
import {EpipremnumDetailsRoute} from "./components/global/privateRoute/epipremnumDetailsRoute";
import {EpipremnumEditComponent} from "./components/epipremnum/epipremnumDetails/epipremnumEditComponent";
import {Strings} from "./Resources/Strings";
import {
    EpipremnumRawPictureCreateCommercialPictureComponent
} from "./components/epipremnumRawPicture/epipremnumRawPictureCreateCommercialPicture/epipremnumRawPictureCreateCommercialPictureComponent";
import {ReportsComponent} from "./components/report/reports/reportsComponent";
import {
    MercadoLibrePendingOrdersComponent
} from "./components/report/mercadoLibrePendingOrders/mercadoLibrePendingOrdersComponent";

export const RoutesComponent = () => {

    const {isInProgress} = useSelector((root: RootState) => root.progress);

    return <div hidden={isInProgress}>
        <Routes>
            <Route path="/" element={<HomeComponent/>}/>
            <Route path="/userdetails" element={<UserDetailsComponent/>}/>

            <Route path="/epipremnum/create" element={<EpipremnumCreateComponent/>}/>
            <Route path="/epipremnum/scan" element={<EpipremnumScanComponent/>}/>
            <Route path="/epipremnum/:id" element={<EpipremnumDetailsRoute/>}/>
            <Route path="/epipremnum/all" element={<EpipremnumAllComponent/>}/>
            <Route path="/epipremnum/edit/:id" element={<EpipremnumEditComponent/>}/>

            <Route path="/epipremnumRawPicture/create/:epipremnumId"
                   element={<EpipremnumRawPictureCreateComponent/>}/>
            <Route path="/epipremnumRawPicture/:id"
                   element={<EpipremnumRawPictureDetailsComponent/>}/>
            <Route path="/epipremnumRawPicture/create_commercial_picture/:id"
                   element={<EpipremnumRawPictureCreateCommercialPictureComponent/>}/>

            <Route path="/report"
                   element={<ReportsComponent/>}/>

            <Route path="/mercadoLibrePendingOrders"
                   element={<MercadoLibrePendingOrdersComponent/>}/>

            <Route path="*" element={<GenericNotFoundedComponent/>}/>
            <Route path="/forbidden" element={<>{Strings.Forbidden}</>}/>
        </Routes>
    </div>
}
