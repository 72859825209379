import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from "./App";
import {BrowserRouter} from "react-router-dom";
import {createTheme, makeStyles, ThemeOptions, ThemeProvider} from "@mui/material";
import {persistor, store} from "./redux/store";
import {Provider} from "react-redux";
import {PersistGate} from 'redux-persist/integration/react';
import {SnackbarProvider} from "notistack";
import {ConfirmOptions, ConfirmProvider} from "material-ui-confirm";
import {Strings} from "./Resources/Strings";
import {AppState, Auth0Provider, User} from "@auth0/auth0-react";
import {auth0Audience, auth0ClientId, auth0Domain} from "./api/ApiConfig";


export const themeOptions = {
    palette: {
        primary: {
            main: '#282c2f',
        },
        secondary: {
            main: '#eaeaea',
        },
        error: {
            main: '#e60606',
        },
        warning: {
            main: '#e0a404',
        },
        info: {
            main: '#839081',
        },
        success: {
            // main: '#00968e',
            main: '#007072',
        },
        text: {
            primary: 'rgba(40,44,47,0.87)',
            secondary: 'rgba(40,44,47,0.54)',
            disabled: 'rgba(40,44,47,0.38)',
            hint: 'rgba(40,44,47,0.38)',
        },
        divider: 'rgba(67,67,67,0.12)',
    },
    typography: {
        fontFamily: 'Sorts Mill Goudy',
        allVariants: {
            color: 'rgba(40,44,47,0.87)',
        }
    },
    props: {
        MuiButton: {
            // size: 'large',
            dense: true
        },
        MuiButtonGroup: {
            size: 'small',
        },
        MuiCheckbox: {
            size: 'small',
        },
        MuiFab: {
            size: 'small',
        },
        MuiFormControl: {
            margin: 'dense',
            size: 'small',
        },
        MuiFormHelperText: {
            margin: 'dense',
        },
        MuiIconButton: {
            size: 'small',
        },
        MuiInputBase: {
            margin: 'dense',
        },
        MuiInputLabel: {
            margin: 'dense',
        },
        MuiRadio: {
            size: 'small',
        },
        MuiSwitch: {
            size: 'small',
        },
        MuiTextField: {
            margin: 'dense',
            size: 'small',
        },
    },
};

export const theme = createTheme(themeOptions)

const confirmOptions: ConfirmOptions = {
    title: Strings.AreYouSureOfThisAction,
    cancellationText: Strings.No,
    confirmationText: Strings.Yes,

    cancellationButtonProps: {variant: 'outlined', color: 'error'},
    confirmationButtonProps: {variant: 'outlined', color: 'primary'}
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const callback = (appState: AppState | undefined, user: User | undefined) => {
    console.log(appState)
    console.log(user)
    if (user){

    }
}

root.render(


    <Auth0Provider domain={auth0Domain} clientId={auth0ClientId}
                   onRedirectCallback={callback}
                   useRefreshTokens
                   audience={auth0Audience}
                   cacheLocation="localstorage"
                   redirectUri={window.location.origin}>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <ThemeProvider theme={theme}>
                        <ConfirmProvider defaultOptions={confirmOptions}>
                            <SnackbarProvider>
                                <App/>
                            </SnackbarProvider>
                        </ConfirmProvider>
                    </ThemeProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </Auth0Provider>
);
